import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Image } from "cloudinary-react";
import { EmailVerificationMessage } from "components/EmailVerificationMessage";
import useBasket from "hooks/useBasket";
import useClient from "hooks/useClient";
import useCurrentUser from "hooks/useCurrentUser";
import useDeleteAuth from "hooks/useDeleteAuth";
import Link from "next/link";
import { Fragment } from "react";
import { cn } from "utils/cn";

const Header = () => {
  const { data: client } = useClient();
  const { data: currentUser } = useCurrentUser();
  const { data: basketData } = useBasket();
  const [deleteAuth] = useDeleteAuth();

  return (
    <div className="sticky top-0 z-10 bg-white">
      <div className="border-b border-gray-200 bg-white">
        <Popover>
          <div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 sm:py-6">
            <nav
              className="relative flex items-center justify-between sm:h-10 md:justify-center"
              aria-label="Global"
            >
              <div className="flex w-full items-center justify-between md:w-auto">
                <Link href="/">
                  <a className="flex flex-1 cursor-pointer" data-cy="link-home">
                    {client?.clientLogo?.path &&
                      client?.headerDisplay !== "nameOnly" && (
                        <Image
                          className="h-8 w-auto sm:h-10"
                          cloudName="pembee"
                          publicId={client?.clientLogo?.filename}
                          height="40"
                          dpr="2"
                          alt="Logo"
                        />
                      )}
                    {client?.headerDisplay !== "logoOnly" && (
                      <span
                        className={cn(
                          "self-center text-center text-base font-bold text-indigo-600 sm:text-xl",
                          client?.clientLogo?.path &&
                            client?.headerDisplay !== "nameOnly" &&
                            "ml-2"
                        )}
                        data-cy="client-name"
                      >
                        {client?.name}
                      </span>
                    )}
                  </a>
                </Link>

                <div className="-my-2 mr-1 md:hidden">
                  <Link href="/cart">
                    <a
                      className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:bg-gray-100 focus:text-gray-500 focus:outline-none"
                      data-cy="link-basket-mobile"
                    >
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
                      </svg>

                      {basketData?.lineItemsCount > 0 && (
                        <span
                          style={{ minWidth: "1rem" }}
                          className="absolute right-0 top-0 block h-4 rounded-full bg-blue-100 text-center text-xs font-medium leading-4 text-blue-800"
                          data-cy="basket-count-mobile"
                        >
                          {basketData.lineItemsCount}
                        </span>
                      )}
                    </a>
                  </Link>
                </div>

                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div
                id="md-cart-user-links"
                className="items-center justify-end space-x-4 sm:space-x-8 md:flex md:flex-1 lg:w-0"
              >
                <Link href="/cart">
                  <a
                    className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-500 focus:bg-gray-100 focus:text-gray-500 focus:outline-none"
                    data-cy="link-basket"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
                    </svg>
                    {basketData?.lineItemsCount > 0 && (
                      <span
                        style={{ minWidth: "1rem" }}
                        className="absolute right-0 top-0 block h-4 rounded-full bg-blue-100 text-center text-xs font-medium leading-4 text-blue-800"
                        data-cy="basket-count"
                      >
                        {basketData.lineItemsCount}
                      </span>
                    )}
                  </a>
                </Link>

                {currentUser ? (
                  <>
                    <Link href="/account">
                      <a
                        className="whitespace-no-wrap cursor-pointer text-sm font-medium leading-6 text-gray-500 hover:text-gray-900 focus:text-gray-900 focus:outline-none sm:text-base"
                        data-cy="link-my-account"
                      >
                        My account
                      </a>
                    </Link>
                    {currentUser.accessLevel >= 3 && (
                      <Link href="/admin">
                        <a
                          className="whitespace-no-wrap cursor-pointer text-sm font-medium leading-6 text-gray-500 hover:text-gray-900 focus:text-gray-900 focus:outline-none sm:text-base"
                          data-cy="link-admin"
                        >
                          Admin
                        </a>
                      </Link>
                    )}
                    <a
                      onClick={() => deleteAuth()}
                      className="whitespace-no-wrap cursor-pointer text-sm font-medium leading-6 text-gray-500 hover:text-gray-900 focus:text-gray-900 focus:outline-none sm:text-base"
                      data-cy="link-logout"
                    >
                      Log out
                    </a>
                  </>
                ) : (
                  <>
                    <Link href="/login">
                      <a
                        className="whitespace-no-wrap text-sm font-medium leading-6 text-gray-500 hover:text-gray-900 focus:text-gray-900 focus:outline-none sm:text-base"
                        data-cy="link-login"
                      >
                        Log in
                      </a>
                    </Link>
                    <span className="inline-flex rounded-md shadow-sm">
                      <Link href="/register">
                        <a
                          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          data-cy="link-register"
                        >
                          Register
                        </a>
                      </Link>
                    </span>
                  </>
                )}
              </div>
            </nav>
          </div>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
            >
              {({ close }) => (
                <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                  <div className="flex items-center justify-between px-5 pt-4">
                    <div className="flex items-center">
                      {client?.clientLogo?.path &&
                        client?.headerDisplay !== "nameOnly" && (
                          <Image
                            className="h-8 w-auto sm:h-10"
                            cloudName="pembee"
                            publicId={client?.clientLogo?.filename}
                            height="40"
                            dpr="2"
                            alt="logo"
                          />
                        )}
                      {client?.headerDisplay !== "logoOnly" && (
                        <span
                          className={cn(
                            "self-center text-center text-base font-bold text-indigo-600 sm:text-xl",
                            client?.clientLogo?.path &&
                              client?.headerDisplay !== "nameOnly" &&
                              "ml-2"
                          )}
                          data-cy="client-name"
                        >
                          {client?.name}
                        </span>
                      )}
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pb-3 pt-2" role="none">
                    {currentUser && (
                      <Link href="/account">
                        <a
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                          role="menuitem"
                          data-cy="link-my-account-mobile"
                        >
                          My account
                        </a>
                      </Link>
                    )}

                    {currentUser?.accessLevel >= 3 && (
                      <Link href="/admin">
                        <a
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                          role="menuitem"
                          data-cy="link-admin-mobile"
                        >
                          Admin
                        </a>
                      </Link>
                    )}
                  </div>
                  {currentUser ? (
                    <a
                      onClick={() => {
                        deleteAuth();
                        close();
                      }}
                      className="block w-full cursor-pointer bg-gray-50 px-5 py-3 text-center font-medium text-indigo-600 hover:bg-gray-100"
                    >
                      Log out
                    </a>
                  ) : (
                    <Link href="/login">
                      <a className="block w-full bg-gray-50 px-5 py-3 text-center font-medium text-indigo-600 hover:bg-gray-100">
                        Log in
                      </a>
                    </Link>
                  )}
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
      <EmailVerificationMessage />
    </div>
  );
};

export default Header;
